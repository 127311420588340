import Auth from "@aws-amplify/auth";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import myAlert from "../../../components/Alert";
import { Button } from "../../../components/Button";
import { Header } from "../../../components/Header";
import { Input } from "../../../components/Input";
import { Label } from "../../../components/Label";
import { ViewLoader } from "../../../components/ViewLoader";
import ApiError from "../../../errors/ApiError";
import { theme } from "../../../global/styles/theme";
import { AuthParamList } from "../../../routes/auth.routes";
import { api } from "../../../services/api";
import {
  yup,
  yupValidate,
  yupValidationCode,
  yupValidationCpfCnpj,
} from "../../../utils/yupValidation";
import { styles } from "./styles";

type ParamProps = RouteProp<AuthParamList, "SignUpConfirmation">;

export function SignUpConfirmation() {
  const navigation = useNavigation();
  const route = useRoute<ParamProps>();

  const [codigoConfirmacao, setCodigoConfirmacao] = useState("");
  const [loading, setLoading] = useState(false);

  const [messageAlert, setMessageAlert] = useState("");

  const { documento } = route.params;

  const validationSchema = yup.object().shape({
    documento: yupValidationCpfCnpj,
    codigoConfirmacao: yupValidationCode,
  });

  async function confirmSignUp() {
    try {
      setLoading(true);

      const data = {
        documento,
        codigoConfirmacao,
      };

      await yupValidate(validationSchema, data);

      await api
        .post("cadastros/confirmar", data)
        .then(() => {
          navigation.navigate("SignIn");
        })
        .catch((err) => {
          console.log(err);
          throw new ApiError(err);
        });
    } catch (err) {
      if (err instanceof Error && err.message) {
        setMessageAlert(err.message);
      } else {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  }

  async function resendConfirmationCode() {
    try {
      setLoading(true);
      await Auth.resendSignUp(documento);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Header title="" />
      <ViewLoader loading={loading}>
        <Label fieldLabel="Agora, informe o código recebido por e-mail ou SMS:" />
        <Input
          value={codigoConfirmacao}
          placeholder="Código em seu e-mail ou SMS."
          inputChange={(text: any) => setCodigoConfirmacao(text)}
          maxLength={6}
          keyboardType="numeric"
          submitFunction={confirmSignUp}
        />

        <View style={styles.viewResendConfirmationCode}>
          <Text style={styles.text1ResendConfirmationCode}>Não recebeu?</Text>
          <TouchableOpacity onPress={resendConfirmationCode}>
            <Text style={styles.text2ResendConfirmationCode}>
              Reenviar código
            </Text>
          </TouchableOpacity>
        </View>

        <Button fieldLabel="Confirmar" onPress={confirmSignUp} />
      </ViewLoader>
      {messageAlert != "" && (
        <AwesomeAlert
          show={messageAlert != ""}
          message={messageAlert}
          showConfirmButton={true}
          confirmButtonColor={theme.colors.primary}
          confirmText="OK"
          onConfirmPressed={() => {
            setMessageAlert("");
          }}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
        />
      )}
    </>
  );
}
