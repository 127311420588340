import React from "react";
import { Background } from "../../../components/Background";
import { Dashboard } from "../../../components/Dashboard";
import { Profile } from "../../../components/Profile";

export function Home() {
  return (
    <Background>
      <Profile />
      <Dashboard />
    </Background>
  );
}
