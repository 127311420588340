import { StyleSheet } from "react-native";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import { theme } from "../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 20,
    flexDirection: "row",
    marginTop: getStatusBarHeight() + 20,
    // marginBottom: 42,
    // backgroundColor: theme.colors.secondary10,
  },

  content: {
    flex: 1,
  },

  greeting: {
    fontFamily: theme.fonts.title500,
    fontSize: 16,
    color: theme.colors.secondary,
    // includeFontPadding: false,
  },

  username: {
    fontFamily: theme.fonts.title700,
    fontSize: 30,
    color: theme.colors.secondary,
    includeFontPadding: false,
  },

  menu: {
    flexDirection: "row",
    alignItems: "center",
  },

  notifications: {
    marginRight: 15,
  },
});
