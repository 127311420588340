import { useFocusEffect } from "@react-navigation/native";
import { AxiosRequestConfig } from "axios";
import { format, parseISO } from "date-fns";
import React, { useCallback, useState } from "react";
import { FlatList, Text, View } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { Card } from "../../../components/Card";
import { Header } from "../../../components/Header";
import { Loader } from "../../../components/Loader";
import ApiError from "../../../errors/ApiError";
import { theme } from "../../../global/styles/theme";
import { useAuth, verificaNotificacoes } from "../../../hooks/auth";
import { api } from "../../../services/api";
import { styles } from "./styles";
interface INotification {
  id: string;
  leitura: string;
  mensagem: string;
  notificada: boolean;
  processamento: string;
  tipo: string;
  titulo: string;
  visibilidade: string;
}

export function Notifications() {
  const [notifications, setNotifications] = useState<INotification[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  const { user } = useAuth();

  async function loadNotifications() {
    try {
      setLoading(true);

      const config: AxiosRequestConfig = {
        params: {
          sort: "visibilidade,desc",
        },
      };

      await api
        .get("notificacoes", config)
        .then((response) => {
          setNotifications(response.data.content);
          verificaNotificacoes(user);
        })
        .catch((err) => {
          console.log(err);
          throw new ApiError(err);
        });
    } catch (err) {
      if (err instanceof ApiError && err.message) {
        setMessageAlert(err.message);
      } else {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  }

  useFocusEffect(
    useCallback(() => {
      loadNotifications();
    }, [])
  );

  return (
    <>
      <Loader loading={loading} />
      <Header title="Notificações" goBack={false} />
      <FlatList
        style={[styles.container]}
        data={notifications}
        keyExtractor={(notification) => notification.id}
        ListEmptyComponent={() => {
          return (
            <View style={[styles.cardView, styles.cardViewListEmpty]}>
              <Text style={[styles.cardTitle, { textAlign: "center" }]}>
                Nenhuma notificação encontrada.
              </Text>
            </View>
          );
        }}
        renderItem={({ item: notification }) => {
          return (
            <Card>
              <View style={styles.cardBadgeLeft}>
                <Text style={styles.cardBadgeText}>{notification.titulo}</Text>
              </View>
              <View style={styles.cardBadgeRight}>
                <Text style={styles.cardBadgeText}>
                  {format(parseISO(notification.visibilidade), "dd/MM/yyyy")}
                </Text>
              </View>
              <View style={{ opacity: notification.leitura ? 0.8 : 1 }}>
                <View style={styles.cardView}>
                  <Text style={styles.cardText}>{notification.mensagem}</Text>
                </View>
              </View>
              <View style={styles.cardView}>
                {!notification.leitura && <View style={styles.checked} />}
              </View>
            </Card>
          );
        }}
      />

      {messageAlert != "" && (
        <AwesomeAlert
          show={messageAlert != ""}
          message={messageAlert}
          showConfirmButton={true}
          confirmButtonColor={theme.colors.primary}
          confirmText="OK"
          onConfirmPressed={() => {
            setMessageAlert("");
          }}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
        />
      )}
    </>
  );
}
