import { StyleSheet } from "react-native";
import { theme } from "../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    marginTop: 20,
  },
  title: {
    marginTop: 10,
    fontFamily: theme.fonts.title700,
    fontSize: 30,
    color: theme.colors.heading,
  },
  subtitleView: {
    width: "80%",
    // alignItems: "center",
    marginTop: 20,
    backgroundColor: theme.colors.secondary,
    paddingHorizontal: 20,
    borderRadius: 8,
    paddingVertical: 10,
  },
  subtitleSubView: {
    flexDirection: "row",
  },

  subtitleTextPrimary: {
    fontFamily: theme.fonts.text400,
    color: theme.colors.primary,
    fontSize: 12,
    marginVertical: 1,
    marginRight: 5,
  },

  subtitleTextSecundary: {
    fontFamily: theme.fonts.text400,
    color: theme.colors.heading,
    fontSize: 12,
    marginVertical: 1,
  },
  footter: {
    flex: 1,
    justifyContent: "flex-end",
    marginBottom: 30,
    paddingHorizontal: 40,
  },
  buttonSignOut: {
    height: 32,
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: theme.colors.primary,
    borderColor: theme.colors.primary,
    borderWidth: 1,
    borderRadius: 16,
  },
});
