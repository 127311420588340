import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { theme } from "../../global/styles/theme";
import { useAuth } from "../../hooks/auth";
import { applyMask } from "../../utils/mask";
import { ButtonMyData } from "../ButtonMyData";
import { ModalView } from "../ModalView";
import { SocialMediaView } from "../SocialMediaView";
import { styles } from "./styles";

type Props = {
  openModal: boolean;
  closeModalView: () => void;
};

export function MyData({ openModal = false, closeModalView }: Props) {
  const { user, signOut } = useAuth();

  return (
    <ModalView visible={openModal} closeModal={closeModalView}>
      <View style={styles.container}>
        <ButtonMyData title={user.iniciaisNome} />
        <Text style={styles.title}>{user.primeiroNome}</Text>
        <View style={styles.subtitleView}>
          <View style={styles.subtitleSubView}>
            <Text style={styles.subtitleTextPrimary}>Nome:</Text>
            <Text style={styles.subtitleTextSecundary}>{user.nome}</Text>
          </View>
          <View style={styles.subtitleSubView}>
            <Text style={styles.subtitleTextPrimary}>
              {user.documento && user.documento.length > 11 ? "CNPJ:" : "CPF:"}
            </Text>
            <Text style={styles.subtitleTextSecundary}>
              {applyMask(user.documento, "cpfCnpj")}
            </Text>
          </View>

          <View style={styles.subtitleSubView}>
            <Text style={styles.subtitleTextPrimary}>Celular:</Text>
            <Text style={styles.subtitleTextSecundary}>
              {applyMask(user.celular, "celular11")}
            </Text>
          </View>

          <View style={styles.subtitleSubView}>
            <Text style={styles.subtitleTextPrimary}>E-mail:</Text>
            <Text style={styles.subtitleTextSecundary}>{user.email}</Text>
          </View>
        </View>
      </View>
      <SocialMediaView color={theme.colors.heading} />
      <View style={styles.footter}>
        <Text
          style={[
            styles.subtitleTextSecundary,
            {
              color: theme.colors.heading,
              fontSize: 10,
              marginBottom: 10,
              alignSelf: "center",
              marginTop: 40,
            },
          ]}
        >
          Versão 1.0.2
        </Text>
        <TouchableOpacity
          style={styles.buttonSignOut}
          activeOpacity={0.8}
          onPress={signOut}
        >
          <Text
            style={[
              styles.subtitleTextSecundary,
              { color: theme.colors.heading },
            ]}
          >
            SAIR DA CONTA
          </Text>
        </TouchableOpacity>
      </View>
    </ModalView>
  );
}
