import { FontAwesome } from "@expo/vector-icons";
import React, { ReactNode } from "react";
import { Modal, ModalProps, View } from "react-native";
import { theme } from "../../global/styles/theme";
import { styles } from "./styles";

type Props = ModalProps & {
  children: ReactNode;
  closeModal: () => void;
};

export function ModalView({ children, closeModal, ...rest }: Props) {
  return (
    <Modal transparent animationType="slide" statusBarTranslucent {...rest}>
      <View style={styles.container}>
        <FontAwesome
          name="close"
          size={24}
          color={theme.colors.heading}
          style={styles.close}
          onPress={closeModal}
        />
        {children}
      </View>
    </Modal>
  );
}
