import { StyleSheet } from "react-native";
import { theme } from "../../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  cardBadgeLeft: {
    left: 1,
    top: 1,
    marginTop: -22,
    marginLeft: -22,
    marginBottom: 5,
    width: 200,
    position: "absolute",
    backgroundColor: theme.colors.primary,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    fontSize: 14,
  },
  cardBadgeRight: {
    right: 1,
    top: 1,
    marginTop: -22,
    marginRight: -22,
    marginBottom: 5,
    width: 100,
    position: "absolute",
    backgroundColor: theme.colors.primary,
    borderBottomLeftRadius: 10,
    borderTopRightRadius: 10,
    fontSize: 14,
  },
  cardBadgeText: {
    fontFamily: theme.fonts.title500,
    color: theme.colors.secondary,
    textAlign: "center",
  },
  cardView: {
    flexDirection: "row",
    maxWidth: "100%",
  },
  cardViewListEmpty: {
    marginTop: 20,
  },
  cardTitle: {
    color: theme.colors.primary,
    fontFamily: theme.fonts.title700,
    fontSize: 14,
    textTransform: "uppercase",
    marginRight: 5,
    flex: 1,
  },
  cardText: {
    marginTop: 10,
    fontFamily: theme.fonts.title500,
    color: "#464646",
    fontSize: 14,
  },
  checked: {
    top: 0,
    position: "absolute",
    right: -10,
    width: 10,
    height: 10,
    backgroundColor: theme.colors.primary,
    borderRadius: 10,
  },
});
