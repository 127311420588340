import { StyleSheet } from "react-native";
import { theme } from "../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 14,
    color: theme.colors.secondary,
    textTransform: "uppercase",
    // fontWeight: "bold",
    fontFamily: theme.fonts.text500,
  },
});
