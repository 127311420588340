import React, { useState } from "react";
import { Text, View } from "react-native";
import { useAuth } from "../../hooks/auth";
import { ButtonMyData } from "../ButtonMyData";
import { ButtonNotification } from "../ButtonNotification";
import { MyData } from "../MyData";
import { styles } from "./styles";

export function Profile() {
  const { user } = useAuth();
  const [openModal, setOpenModal] = useState(false);

  function openModalView() {
    setOpenModal(true);
  }

  function closeModalView() {
    setOpenModal(false);
  }

  function verificaHora() {
    var stamp = new Date();

    var hours = stamp.getHours();

    if (hours >= 0 && hours < 6) {
      return "Boa noite";
    } else if (hours >= 6 && hours < 12) {
      return "Bom dia";
    } else if (hours >= 12 && hours < 18) {
      return "Boa tarde";
    } else if (hours >= 18 && hours < 24) {
      return "Boa noite";
    }
  }

  return (
    <>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.greeting}>{verificaHora()},</Text>
          <Text style={styles.username}>{user.primeiroNome}</Text>
        </View>

        <View style={styles.menu}>
          <ButtonMyData title={user.iniciaisNome} onPress={openModalView} />
        </View>
      </View>
      {openModal && (
        <MyData openModal={openModal} closeModalView={closeModalView} />
      )}
    </>
  );
}
