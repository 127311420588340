import { StyleSheet } from "react-native";
import { theme } from "../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: 500,
    fontSize: 18,
    color: theme.colors.secondary,
    fontFamily: theme.fonts.title700,
  },
});
