import React from "react";

import { TextInput, TextInputProps, View } from "react-native";
import { theme } from "../../global/styles/theme";
import { applyMask, IMask } from "../../utils/mask";
import { FontAwesome } from "@expo/vector-icons";

import { styles } from "./styles";
import { useState } from "react";

type Props = IMask &
  TextInputProps & {
    inputChange: (value: string | undefined) => void;
    submitFunction?: () => void;
    iconShowPassword?: boolean;
  };

export function Input({
  value,
  inputChange,
  mask,
  submitFunction,
  iconShowPassword = false,
  secureTextEntry,
  ...rest
}: Props) {
  const [secureText, setSecureText] = useState(secureTextEntry);

  function handleChange(text: string) {
    const value = applyMask(text, mask);
    inputChange(value);
  }

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.input}
        value={value}
        onChangeText={(text) => handleChange(text)}
        placeholderTextColor={theme.colors.secondary}
        secureTextEntry={secureText}
        onKeyPress={(event: any) => {
          if (event.key == "Enter" && submitFunction) submitFunction();
        }}
        {...rest}
      />
      {iconShowPassword && (
        <FontAwesome
          style={styles.icon}
          name={secureText ? "eye" : "eye-slash"}
          size={22}
          onPress={() => {
            setSecureText(!secureText);
          }}
        />
      )}
    </View>
  );
}
