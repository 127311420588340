import { StyleSheet } from "react-native";
import { theme } from "../../../global/styles/theme";

export const styles = StyleSheet.create({
  viewResendConfirmationCode: {
    marginTop: 20,
    flexDirection: "row",
  },
  textResendConfirmationCode: {
    fontSize: 14,
    color: theme.colors.primary,
    fontWeight: "bold",
    marginLeft: 5,
    textDecorationLine: "underline",
  },
});
