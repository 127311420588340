import { FontAwesome5 } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import { AxiosRequestConfig } from "axios";
import { format, parseISO } from "date-fns";
import React, { useCallback, useState } from "react";
import { FlatList, Text, View } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { Card } from "../../../components/Card";
import { Header } from "../../../components/Header";
import { Loader } from "../../../components/Loader";
import ApiError from "../../../errors/ApiError";
import { theme } from "../../../global/styles/theme";
import { api } from "../../../services/api";
import { formatNumber } from "../../../utils/formatNumber";
import { styles } from "./styles";
interface IContract {
  id: string;
  numero: string;
  plano: string;
  criacao: string;
  status: string;
  valor: Number;
}

export function Contracts() {
  const [contracts, setContracts] = useState<IContract[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  async function loadContracts() {
    try {
      setLoading(true);

      const config: AxiosRequestConfig = {
        params: {
          sort: "criacao",
        },
      };

      await api
        .get("contratos", config)
        .then((response) => {
          setContracts(response.data.content);
        })
        .catch((err) => {
          console.log(err);
          throw new ApiError(err);
        });
    } catch (err) {
      if (err instanceof ApiError && err.message) {
        setMessageAlert(err.message);
      } else {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  }

  useFocusEffect(
    useCallback(() => {
      loadContracts();
    }, [])
  );

  return (
    <>
      <Loader loading={loading} />
      <Header title="Contratos" goBack={false} />
      <FlatList
        style={styles.container}
        data={contracts}
        keyExtractor={(contract) => contract.id}
        ListEmptyComponent={() => {
          return (
            <View style={[styles.cardView, styles.cardViewListEmpty]}>
              <Text style={styles.cardText}>Nenhum contrato encontrado.</Text>
            </View>
          );
        }}
        renderItem={({ item: contract }) => {
          return (
            <Card>
              <FontAwesome5 name="file-signature" style={styles.cardIcon} />
              <View style={styles.cardView}>
                <View style={styles.subCardView}>
                  {/* <Text style={styles.cardTitle}>Plano</Text> */}
                  <Text style={styles.cardText}>{contract.plano}</Text>
                </View>
              </View>
              <View style={[styles.cardView, styles.separation]}>
                <View style={styles.subCardView}>
                  <Text style={styles.cardTitle}>Data</Text>
                  <Text style={styles.cardText}>
                    {format(parseISO(contract.criacao), "dd/MM/yyyy")}
                  </Text>
                </View>
                <View style={styles.subCardView}>
                  <Text style={styles.cardTitle}>Valor</Text>
                  <Text style={styles.cardText}>
                    {formatNumber(contract.valor)}
                  </Text>
                </View>
              </View>
            </Card>
          );
        }}
      />

      {messageAlert != "" && (
        <AwesomeAlert
          show={messageAlert != ""}
          message={messageAlert}
          showConfirmButton={true}
          confirmButtonColor={theme.colors.primary}
          confirmText="OK"
          onConfirmPressed={() => {
            setMessageAlert("");
          }}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
        />
      )}
    </>
  );
}
