import React from "react";
import { Text, TextProps } from "react-native";
import { styles } from "./styles";

type Props = TextProps & {
  fieldLabel: string;
};

export function Label({ fieldLabel, ...rest }: Props) {
  return (
    <Text style={styles.container} {...rest}>
      {fieldLabel}
    </Text>
  );
}
