import { StyleSheet } from "react-native";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import { theme } from "../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    // backgroundColor: "#F8F8F8",
    padding: 10,
  },
  subContainer: {
    paddingBottom: 20,
  },
  cardLine: {
    flexDirection: "row",
    justifyContent: "center",
  },
  cardView: {
    flexDirection: "column",
    flex: 1,
  },
  cardTitle: {
    color: theme.colors.primary,
    fontFamily: theme.fonts.title700,
    fontSize: 13,
    textTransform: "uppercase",
    textAlign: "center",
  },
  cardText: {
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: theme.fonts.title500,
    color: "#464646",
    fontSize: 60,
  },
});
