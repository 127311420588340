import axios from "axios";

const { URL_API } = process.env;
const api = axios.create({
  baseURL: URL_API,
});

function clearToken() {
  api.defaults.headers.authorization = null;
}

function setToken(accessToken: string) {
  api.defaults.headers.authorization = `Bearer ${accessToken}`;
}

export { api, setToken, clearToken };
