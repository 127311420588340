/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const { AWS_PROJECT_REGION } = process.env;
const { AWS_COGNITO_IDENTITY_POOL_ID } = process.env;
const { AWS_COGNITO_REGION } = process.env;
const { AWS_USER_POOLS_ID } = process.env;
const { AWS_USER_POOLS_WEB_CLIENT_ID } = process.env;

const awsmobile = {
  aws_project_region: AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: AWS_COGNITO_REGION,
  aws_user_pools_id: AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
};

export default awsmobile;
