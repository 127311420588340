import { AntDesign, Feather, MaterialIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import { AxiosRequestConfig } from "axios";
import { format, parseISO } from "date-fns";
import React, { useCallback, useState } from "react";
import {
  Clipboard,
  FlatList,
  Linking,
  Platform,
  Share,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { RectButton } from "react-native-gesture-handler";
import { Card } from "../../../components/Card";
import { Header } from "../../../components/Header";
import { Loader } from "../../../components/Loader";
import ApiError from "../../../errors/ApiError";
import { theme } from "../../../global/styles/theme";
import { api } from "../../../services/api";
import { formatNumber } from "../../../utils/formatNumber";
import { styles } from "./styles";

interface IInvoices {
  id: string;
  numero: string;
  emissao: string;
  vencimento: string;
  valor: Number;
  vencida?: boolean;
  boleto?: string;
  linhaDigitavel?: string;
  liquidacao?: string;
  cliente: {
    id: string;
    nome: string;
    documento: string;
    celular: string;
    email: string;
  };
}

export function Invoices() {
  const [abertas, setAbertas] = useState<IInvoices[]>([]);
  const [liquidadas, setLiquidadas] = useState<IInvoices[]>([]);
  const [loading, setLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  async function loadData() {
    try {
      setLoading(true);

      const config: AxiosRequestConfig = {
        params: {
          sort: "vencimento,desc",
        },
      };

      await api
        .get("faturas/abertas", config)
        .then((response) => {
          setAbertas(response.data.content);
        })
        .catch((err) => {
          console.log(err);
          throw new ApiError(err);
        });
      await api
        .get("faturas/liquidadas", config)
        .then((response) => {
          setLiquidadas(response.data.content);
        })
        .catch((err) => {
          console.log(err);
          throw new ApiError(err);
        });
    } catch (err) {
      if (err instanceof ApiError && err.message) {
        setMessageAlert(err.message);
      } else {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  }

  function copyText(text: string | undefined) {
    if (!text) return;
    if (Platform.OS == "web") {
      Clipboard.setString(text);
      setMessageAlert("Código copiado para a área de transferência.");
    } else {
      Share.share({
        message: text,
      });
    }
  }

  function openURL(url: string | undefined) {
    if (!url) return;

    if (Platform.OS == "web") {
      window.open(url, "_blank");
    } else {
      Linking.canOpenURL(url).then((supported) => {
        if (supported) {
          Linking.openURL(url);
        } else {
          console.log("Don't know how to open URI: " + url);
        }
      });
    }
  }

  useFocusEffect(
    useCallback(() => {
      loadData();
    }, [])
  );

  return (
    <>
      <Loader loading={loading} />
      <Header title="Faturas" goBack={false} />
      <FlatList
        style={styles.container}
        data={abertas.concat(liquidadas)}
        keyExtractor={(invoice) => invoice.id}
        ListEmptyComponent={() => {
          return (
            <View style={[styles.cardView, styles.cardViewListEmpty]}>
              <Text style={styles.cardText}>Nenhuma fatura encontrada.</Text>
            </View>
          );
        }}
        renderItem={({ item: invoice }) => {
          return (
            <Card>
              {!invoice.liquidacao && (
                <>
                  {invoice.vencida && (
                    <View style={styles.cardBadgeExpired}>
                      <Text style={styles.cardBadgeTextExpired}>Vencida</Text>
                    </View>
                  )}
                  {!invoice.vencida && (
                    <View style={styles.cardBadgeUnpaid}>
                      <Text style={styles.cardBadgeText}>Aberta</Text>
                    </View>
                  )}

                  <View style={styles.cardView}>
                    <View style={styles.subCardView}>
                      <Text style={styles.cardTitle}>Documento</Text>
                      <Text style={styles.cardText}>{invoice.numero}</Text>
                    </View>
                    <View style={styles.subCardView}>
                      <Text style={styles.cardTitle}>Vencimento</Text>
                      <Text
                        style={[styles.cardText, styles.textDecorationLineNone]}
                      >
                        {format(parseISO(invoice.vencimento), "dd/MM/yyyy")}
                      </Text>
                    </View>
                    <View style={styles.subCardView}>
                      <Text style={styles.cardTitle}>Valor</Text>
                      <Text
                        style={[styles.cardText, styles.textDecorationLineNone]}
                      >
                        {formatNumber(invoice.valor)}
                      </Text>
                    </View>
                  </View>
                  {invoice.boleto && (
                    <View style={styles.cardView}>
                      <TouchableOpacity
                        activeOpacity={0.8}
                        onPress={() => copyText(invoice.linhaDigitavel)}
                        style={styles.buttonAction}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            marginVertical: 10,
                            alignItems: "center",
                          }}
                        >
                          <Feather
                            name="copy"
                            size={24}
                            style={styles.iconButtonAction}
                          />
                          <Text style={styles.textButtonAction}>Código</Text>
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        activeOpacity={0.8}
                        onPress={() => openURL(invoice.boleto)}
                        style={styles.buttonAction}
                      >
                        <View style={styles.viewDownload}>
                          <AntDesign
                            name="download"
                            size={24}
                            style={styles.iconButtonAction}
                          />
                          <Text style={styles.textButtonAction}>Boleto</Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  )}
                </>
              )}

              {invoice.liquidacao && (
                <>
                  <View style={styles.cardBadgePaid}>
                    <Text style={styles.cardBadgeText}>Paga</Text>
                  </View>
                  <View style={styles.cardViewPaid}>
                    <View style={styles.cardView}>
                      <View style={styles.subCardView}>
                        <Text style={styles.cardTitle}>Documento</Text>
                        <Text style={styles.cardText}>{invoice.numero}</Text>
                      </View>
                      <View style={styles.subCardView}>
                        <Text style={styles.cardTitle}>Vencimento</Text>
                        <Text
                          style={[
                            styles.cardText,
                            styles.textDecorationLineNone,
                          ]}
                        >
                          {format(parseISO(invoice.vencimento), "dd/MM/yyyy")}
                        </Text>
                      </View>
                      <View style={styles.subCardView}>
                        <Text style={styles.cardTitle}>Valor</Text>
                        <Text
                          style={[
                            styles.cardText,
                            styles.textDecorationLineNone,
                          ]}
                        >
                          {formatNumber(invoice.valor)}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.cardView, styles.separation]}>
                      <View style={styles.subCardView}>
                        <Text style={styles.cardTitle}>Pagamento</Text>
                        <Text style={styles.cardText}>
                          {format(parseISO(invoice.liquidacao), "dd/MM/yyyy")}
                        </Text>
                      </View>
                    </View>
                  </View>
                </>
              )}
            </Card>
          );
        }}
      />

      {messageAlert != "" && (
        <AwesomeAlert
          show={messageAlert != ""}
          message={messageAlert}
          showConfirmButton={true}
          confirmButtonColor={theme.colors.primary}
          confirmText="OK"
          onConfirmPressed={() => {
            setMessageAlert("");
          }}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
        />
      )}
    </>
  );
}
