import _ from "lodash";

const AuthErrorCodes = {
  UserNotConfirmedException: "UserNotConfirmedException",
  UserNotFoundException: "UserNotFoundException",
  NotAuthorizedException: "NotAuthorizedException",
  PasswordResetRequiredException: "PasswordResetRequiredException",
  LimitExceededException: "LimitExceededException",
  ExpiredCodeException: "ExpiredCodeException",
  CodeMismatchException: "CodeMismatchException",
};

const AuthErrors = {
  UserNotConfirmedException:
    "Ops, sua identidade ainda não confirmado. Vamos confirmar?",
  UserNotFoundException: "Ops, não encontramos um usuário com esse CPF/CNPJ.",
  NotAuthorizedException:
    "Ops, o CPF/CNPJ e/ou a senha informados estão incorretos.",
  PasswordResetRequiredException:
    "Ops, sua senha está expirada. Por favor, redefina.",
  LimitExceededException:
    "Ops, você atingiu o número máximo de tentativas. Tente novamente em alguns instantes.",
  ExpiredCodeException:
    "Ops, o código informado está expirado. Por favor, solicite um novo código.",
  CodeMismatchException:
    "Ops, o código informado é inválido. Verifique o último código recebido e tente novamente.",
};

class AuthError extends Error {
  constructor(code: string) {
    super();
    this.name = "Vivavox";
    this.message = _.get(AuthErrors, code);
    this.stack = new Error().stack;
    Object.setPrototypeOf(this, AuthError.prototype);
  }
}

export { AuthErrorCodes, AuthErrors, AuthError };
