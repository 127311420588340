import React from "react";

import { Text, View } from "react-native";
import { RectButton, RectButtonProps } from "react-native-gesture-handler";

import { styles } from "./styles";

type Props = RectButtonProps & {
  fieldLabel: string;
};

export function HyperButton({ fieldLabel, ...rest }: Props) {
  return (
    <View style={styles.container}>
      <RectButton {...rest}>
        <Text style={styles.text}>{fieldLabel}</Text>
      </RectButton>
    </View>
  );
}
