import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { theme } from "../global/styles/theme";
import { ForgotPassword } from "../screens/auth/ForgotPassword";
import { ForgotPasswordConfirmation } from "../screens/auth/ForgotPasswordConfirmation";
import { ForgotPasswordSignUpConfirmation } from "../screens/auth/ForgotPasswordSignUpConfirmation";
import { SignIn } from "../screens/auth/SignIn";
import { SignUp } from "../screens/auth/SignUp";
import { SignUpConfirmation } from "../screens/auth/SignUpConfirmation";
import { SignUpPassword } from "../screens/auth/SignUpPassword";
import { SignUpValidation } from "../screens/auth/SignUpValidation";

const { Navigator, Screen } = createStackNavigator();

export type AuthParamList = {
  SignUpPassword: {
    user: {
      documento: string;
      email: string;
      celular: string;
    };
  };
  SignUpValidation: {
    user: {
      documento: string;
      email: string;
      celular: string;
    };
  };
  SignUpConfirmation: {
    documento: string;
  };
  ForgotPasswordConfirmation: {
    documento: string;
  };
  ForgotPasswordSignUpConfirmation: {
    documento: string;
  };
};

export function AuthRoutes() {
  return (
    <Navigator
      headerMode="none"
      screenOptions={{
        cardStyle: {
          backgroundColor: theme.colors.secondary100,
        },
      }}
    >
      <Screen name="SignIn" options={{ title: "Entrar" }} component={SignIn} />
      <Screen
        name="SignUp"
        options={{ title: "Primeiro Acesso" }}
        component={SignUp}
      />
      <Screen
        name="SignUpValidation"
        options={{ title: "Primeiro Acesso | Validação" }}
        component={SignUpValidation}
      />
      <Screen
        name="SignUpPassword"
        options={{ title: "Primeiro Acesso | Senha" }}
        component={SignUpPassword}
      />
      <Screen
        name="SignUpConfirmation"
        options={{ title: "Primeiro Acesso | Confirmação" }}
        component={SignUpConfirmation}
      />
      <Screen
        name="ForgotPassword"
        options={{ title: "Esqueci minha Senha" }}
        component={ForgotPassword}
      />
      <Screen
        name="ForgotPasswordConfirmation"
        component={ForgotPasswordConfirmation}
      />
      <Screen
        name="ForgotPasswordSignUpConfirmation"
        component={ForgotPasswordSignUpConfirmation}
      />
    </Navigator>
  );
}
