import "core-js/es6/map";
import "core-js/es6/promise";
import "core-js/es6/set";
import * as yup from "yup";
import {
  ValidationError,
  ValidationErrorCodes,
} from "../errors/ValidationError";

const yupValidationCpfCnpj = yup
  .string()
  .required(ValidationErrorCodes.DOCUMENTO_OBRIGATORIO)
  .min(11, ValidationErrorCodes.DOCUMENTO_TAMANHO_MINIMO)
  .max(14, ValidationErrorCodes.DOCUMENTO_TAMANHO_MAXIMO);

const yupValidationCpfCnpjSignIn = yup
  .string()
  .required(ValidationErrorCodes.DOCUMENTO_OBRIGATORIO);

const yupValidationSenha = yup
  .string()
  .required(ValidationErrorCodes.SENHA_OBRIGATORIA)
  .min(8, ValidationErrorCodes.SENHA_TAMANHO_MINIMO)
  .matches(/\w*[A-Z]\w*/, ValidationErrorCodes.SENHA_LETRA_MAIUSCULA)
  .matches(/\w*[a-z]\w*/, ValidationErrorCodes.SENHA_LETRA_MINUSCULA)
  .matches(/\d/, ValidationErrorCodes.SENHA_NUMERO)
  .matches(
    /[!@#$%^&*()\-_"=+{}; :,<.>]/,
    ValidationErrorCodes.SENHA_CARACTERE_ESPECIAL
  );

const yupValidationSenhaSignIn = yup
  .string()
  .required(ValidationErrorCodes.SENHA_OBRIGATORIA);

const yupValidationConfirmacaoSenha = yup
  .string()
  .required(ValidationErrorCodes.CONFIRMACAO_SENHA_OBRIGATORIA)
  .oneOf([yup.ref("senha")], ValidationErrorCodes.SENHAS_DIFERENTES);

const yupValidationCode = yup
  .string()
  .required(ValidationErrorCodes.CODIGO_CONFIRMACAO_OBRIGATORIO)
  .length(6, ValidationErrorCodes.CODIGO_CONFIRMACAO_TAMANHO_MINIMO);

const yupValidationEmail = yup
  .string()
  .email(ValidationErrorCodes.EMAIL_INVALIDO);

const yupValidationCell = yup
  .string()
  .matches(
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    ValidationErrorCodes.TELEFONE_INVALIDO
  )
  .length(13, ValidationErrorCodes.TELEFONE_TAMANHO_MINIMO);

async function yupValidate(schema: yup.AnySchema, data: Object) {
  try {
    await schema.validate(data, {
      abortEarly: false,
    });
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      throw new ValidationError(err.errors);
    } else {
      console.log(err);
    }
  }
}

export {
  yup,
  yupValidate,
  yupValidationCpfCnpj,
  yupValidationCpfCnpjSignIn,
  yupValidationSenha,
  yupValidationSenhaSignIn,
  yupValidationConfirmacaoSenha,
  yupValidationCode,
  yupValidationEmail,
  yupValidationCell,
};
