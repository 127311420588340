import _ from "lodash";

const ValidationErrorCodes = {
  DOCUMENTO_OBRIGATORIO: "001",
  DOCUMENTO_TAMANHO_MINIMO: "002",
  DOCUMENTO_TAMANHO_MAXIMO: "003",
  SENHA_OBRIGATORIA: "004",
  SENHA_TAMANHO_MINIMO: "005",
  SENHA_LETRA_MAIUSCULA: "006",
  SENHA_LETRA_MINUSCULA: "007",
  SENHA_NUMERO: "008",
  SENHA_CARACTERE_ESPECIAL: "009",
  CONFIRMACAO_SENHA_OBRIGATORIA: "010",
  SENHAS_DIFERENTES: "011",
  CODIGO_CONFIRMACAO_OBRIGATORIO: "012",
  CODIGO_CONFIRMACAO_TAMANHO_MINIMO: "013",
  EMAIL_INVALIDO: "014",
  TELEFONE_INVALIDO: "015",
  TELEFONE_TAMANHO_MINIMO: "016",
};

const ValidationErrors = {
  "001": "CPF/CNPJ é um campo obrigatório!",
  "002": "O CPF/CNPJ deve ter pelo menos 11 caracteres!",
  "003": "O CPF/CNPJ deve ter no máximo 14 caracteres!",
  "004": "Senha é um campo obrigatório!",
  "005": "A senha deve ter pelo menos 8 caracteres!",
  "006": "A senha deve ter uma letra maiúscula!",
  "007": "A senha deve ter uma letra minúscula!",
  "008": "A senha deve ter um número!",
  "009": "A senha deve ter um caractere especial!",
  "010": "Confirmação da senha é um campo obrigatório!",
  "011": "As senhas não coincidem!",
  "012": "Código de confirmação é um campo obrigatório!",
  "013": "O código de confirmação deve ter 6 caracteres!",
  "014": "E-mail inválido!",
  "015": "Número de telefone inválido!",
  "016": "O número de telefone deve ter 13 caracteres!",
};

class ValidationError extends Error {
  constructor(codes: string[]) {
    super();
    this.name = "Vivavox";
    let messages = Array<string>();
    if (codes) {
      codes.forEach((code) => {
        messages.push(_.get(ValidationErrors, code));
      });
    }
    this.message = messages.join("\n");
    this.stack = new Error().stack;
    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}

export { ValidationErrorCodes, ValidationErrors, ValidationError };
