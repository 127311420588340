import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import AwesomeAlert from "react-native-awesome-alerts";
import { Button } from "../../../components/Button";
import { HyperButton } from "../../../components/HyperButton";
import { Input } from "../../../components/Input";
import { Logo } from "../../../components/Logo";
import { SocialMediaView } from "../../../components/SocialMediaView";
import { ViewLoader } from "../../../components/ViewLoader";
import { AuthError, AuthErrorCodes } from "../../../errors/AuthError";
import { theme } from "../../../global/styles/theme";
import { useAuth } from "../../../hooks/auth";
import { numeroPuro } from "../../../utils/mask";
import {
  yup,
  yupValidate,
  yupValidationCpfCnpjSignIn,
  yupValidationSenhaSignIn,
} from "../../../utils/yupValidation";

export function SignIn() {
  const [documento, setDocumento] = useState("");
  const [senha, setSenha] = useState("");

  const [messageAlert, setMessageAlert] = useState("");

  const { signIn, loading } = useAuth();

  const navigation = useNavigation();

  const signInValidationSchema = yup.object().shape({
    username: yupValidationCpfCnpjSignIn,
    password: yupValidationSenhaSignIn,
  });

  async function handleSignIn() {
    try {
      const data = {
        username: numeroPuro(documento),
        password: senha,
      };

      await yupValidate(signInValidationSchema, data);

      try {
        await signIn(data);
      } catch (err: any) {
        console.log(err);
        if (err.code == AuthErrorCodes.UserNotConfirmedException) {
          navigation.navigate("SignUpConfirmation", {
            documento: numeroPuro(documento),
          });
        } else {
          throw new AuthError(err.code);
        }
      }
    } catch (err) {
      if (err instanceof Error && err.message) {
        setMessageAlert(err.message);
      } else {
        console.log(err);
      }
    }
  }

  return (
    <>
      <ViewLoader loading={loading}>
        <Logo />
        <Input
          value={documento}
          placeholder="CPF/CNPJ"
          mask="cpfCnpj"
          inputChange={(text: any) => setDocumento(text)}
          maxLength={18}
          autoCapitalize="none"
          keyboardType="numeric"
        />

        <Input
          value={senha}
          placeholder="SENHA"
          inputChange={(text: any) => setSenha(text)}
          secureTextEntry
          autoCompleteType="password"
          submitFunction={handleSignIn}
          iconShowPassword
        />

        <Button fieldLabel="ENTRAR" onPress={handleSignIn} />

        <HyperButton
          fieldLabel="ESQUECI MINHA SENHA"
          onPress={() => navigation.navigate("ForgotPassword")}
        />

        <HyperButton
          fieldLabel="PRIMEIRO ACESSO"
          onPress={() => navigation.navigate("SignUp")}
        />

        <SocialMediaView />
      </ViewLoader>

      {messageAlert != "" && (
        <AwesomeAlert
          show={messageAlert != ""}
          message={messageAlert}
          showConfirmButton={true}
          confirmButtonColor={theme.colors.primary}
          confirmText="OK"
          onConfirmPressed={() => {
            setMessageAlert("");
          }}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
        />
      )}
    </>
  );
}
