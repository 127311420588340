import { StyleSheet } from "react-native";
import { theme } from "../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    borderWidth: 1,
    // borderColor: Colors.green,
    borderColor: "#f8f8f8",
    elevation: 3,
    backgroundColor: "#FFF",
    shadowOffset: { width: 1, height: 1 },
    shadowColor: theme.colors.secondary,
    shadowOpacity: 0.3,
    shadowRadius: 2,
    marginHorizontal: 10,
    marginVertical: 10,
  },
  content: {
    marginHorizontal: 20,
    marginVertical: 20,
  },
});
