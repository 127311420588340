import Auth from "@aws-amplify/auth";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { Button } from "../../../components/Button";
import { Header } from "../../../components/Header";
import { Input } from "../../../components/Input";
import { Label } from "../../../components/Label";
import { ViewLoader } from "../../../components/ViewLoader";
import { AuthError } from "../../../errors/AuthError";
import { theme } from "../../../global/styles/theme";
import { AuthParamList } from "../../../routes/auth.routes";
import {
  yup,
  yupValidate,
  yupValidationCode,
  yupValidationConfirmacaoSenha,
  yupValidationSenha,
} from "../../../utils/yupValidation";
import { styles } from "./styles";

type ParamProps = RouteProp<AuthParamList, "ForgotPasswordConfirmation">;

export function ForgotPasswordConfirmation() {
  const navigation = useNavigation();
  const route = useRoute<ParamProps>();

  const { documento } = route.params;
  const [senha, setSenha] = useState("");
  const [senhaRepetida, setSenhaRepetida] = useState("");
  const [codigoConfirmacao, setCodigoConfirmacao] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  const validationSchema = yup.object().shape({
    senha: yupValidationSenha,
    senhaRepetida: yupValidationConfirmacaoSenha,
    codigoConfirmacao: yupValidationCode,
  });

  async function postNewPassword() {
    try {
      setLoading(true);

      await yupValidate(validationSchema, {
        senha,
        senhaRepetida,
        codigoConfirmacao,
      });

      await Auth.forgotPasswordSubmit(documento, codigoConfirmacao, senha)
        .then(() => {
          navigation.navigate("SignIn");
        })
        .catch(async (err) => {
          console.log(err);
          throw new AuthError(err.code);
        });
    } catch (err) {
      if (err instanceof Error && err.message) {
        setMessageAlert(err.message);
      } else {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  }

  async function resendForgotPassword() {
    try {
      setLoading(true);
      await Auth.forgotPassword(documento).catch((err) => {
        throw new AuthError(err.code);
      });
    } catch (err) {
      if (err instanceof AuthError && err.message) {
        setMessageAlert(err.message);
      } else {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Header title="" />
      <ViewLoader loading={loading}>
        <Label fieldLabel="Agora, escolha sua nova senha e informe o código recebido por e-mail ou SMS:" />
        <Input
          value={senha}
          placeholder="Nova senha"
          inputChange={(text: any) => setSenha(text)}
          secureTextEntry
          autoCompleteType="password"
        />
        <Input
          value={senhaRepetida}
          placeholder="Repita a nova senha"
          inputChange={(text: any) => setSenhaRepetida(text)}
          secureTextEntry
          autoCompleteType="password"
        />
        <Input
          value={codigoConfirmacao}
          placeholder="Código de confirmação em seu e-mail ou telefone."
          inputChange={(text: any) => setCodigoConfirmacao(text)}
          maxLength={6}
          keyboardType="numeric"
          submitFunction={postNewPassword}
        />

        <View style={styles.viewResendConfirmationCode}>
          <Text style={styles.text1ResendConfirmationCode}>Não recebeu?</Text>
          <TouchableOpacity onPress={resendForgotPassword}>
            <Text style={styles.text2ResendConfirmationCode}>
              Reenviar código
            </Text>
          </TouchableOpacity>
        </View>

        <Button fieldLabel="Confirmar" onPress={postNewPassword} />
      </ViewLoader>

      {messageAlert != "" && (
        <AwesomeAlert
          show={messageAlert != ""}
          message={messageAlert}
          showConfirmButton={true}
          confirmButtonColor={theme.colors.primary}
          confirmText="OK"
          onConfirmPressed={() => {
            setMessageAlert("");
          }}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
        />
      )}
    </>
  );
}
