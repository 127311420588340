export const theme = {
  colors: {
    primary: "#89bc4a",
    secondary: "#464646",

    yellow: "#FFC800",
    red: "#FF142D",

    overlay: "rgba(0,0,0,0.95)",
    highlight: "#ABB1CC",
    heading: "#DDE3F0",

    secondary100: "#161616",
    secondary90: "#464646",
    secondary80: "#3e3e3e",
    secondary85: "#4f4f4f",
    secondary70: "#545454",
    secondary60: "#595959",
    secondary50: "#5e5e5e",
    secondary40: "#464646",
    secondary30: "#9ed655",
    secondary20: "#6d6d6d",
    secondary10: "#727272",
    secondary00: "#777777",
  },

  fonts: {
    title700: "WorkSans_700Bold",
    title500: "WorkSans_500Medium",
    text400: "Inter_400Regular",
    text500: "Inter_500Medium",
  },
};
