import Auth from "@aws-amplify/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ImageBackground } from "react-native";
import myAlert from "../components/Alert";
import { COLLECTION_USERS } from "../config/storages";
import { api, clearToken, setToken } from "../services/api";
import {
  createExpoPushToken,
  deleteExpoPushToken,
} from "../services/expoNotifications";

type User = {
  id: string;
  documento: string;
  nome: string;
  primeiroNome: string;
  iniciaisNome: string;
  email: string;
  emailDesatualizado: boolean;
  celular: string;
  celularDesatualizado: boolean;
  contratos: string;
  faturasAbertas: string;
  faturasAtrasadas: string;
  possuiNotificacao: boolean;
};

type AuthContextData = {
  user: User;
  signIn: (data: SingInProps) => Promise<void>;
  signOut: () => Promise<void>;
  loading: boolean;
};

type AuthProviderProps = {
  children: ReactNode;
};

type SingInProps = {
  username: string;
  password: string;
};

type AuthorizationResponse = {
  signInUserSession: {
    accessToken: {
      jwtToken: string;
    };
  };
};

export const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  async function signOut() {
    try {
      setLoading(true);
      await Auth.signOut().catch((err) => {
        myAlert(err.name, err.message);
      });
      await deleteExpoPushToken();
      clearUser();
    } finally {
      setLoading(false);
    }
  }

  async function signIn(data: SingInProps) {
    try {
      setLoading(true);
      await Auth.signIn(data).then(async (response: AuthorizationResponse) => {
        getUser(response.signInUserSession.accessToken.jwtToken);
        await createExpoPushToken();
      });
    } finally {
      setLoading(false);
    }
  }

  async function getUser(accessToken: string) {
    setLoading(true);

    setToken(accessToken);

    const response = await api.get("clientes");

    const userData = {
      id: response.data.id,
      iniciaisNome: response.data.iniciais,
      primeiroNome: response.data.primeiroNome,
      nome: response.data.nome,
      documento: response.data.documento,
      email: response.data.email,
      emailDesatualizado: response.data.emailDesatualizado,
      celular: response.data.celular,
      celularDesatualizado: response.data.celularDesatualizado,
      contratos: "",
      faturasAbertas: "",
      faturasAtrasadas: "",
      possuiNotificacao: false,
    };

    await verificaResumos(userData);
    await verificaNotificacoes(userData);

    await AsyncStorage.setItem(COLLECTION_USERS, JSON.stringify(userData));
    setUser(userData);
    setLoading(false);
  }

  async function clearUser() {
    clearToken();
    await AsyncStorage.clear();
    setUser({} as User);
    await AsyncStorage.removeItem(COLLECTION_USERS);
  }

  async function loadUser() {
    setLoadingUser(true);
    await Auth.currentAuthenticatedUser()
      .then((response: AuthorizationResponse) => {
        getUser(response.signInUserSession.accessToken.jwtToken);
        setTimeout(() => {
          setLoadingUser(false);
        }, 4500);
      })
      .catch(async (err) => {
        clearUser();
        setLoadingUser(false);
      });
  }

  useEffect(() => {
    loadUser();
  }, []);

  if (loadingUser) {
    return (
      <ImageBackground
        style={{ flex: 1 }}
        source={require("../../assets/splash.gif")}
      />
    );
  }

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

async function verificaResumos(userData: User) {
  const response = await api.get("resumos");
  userData.contratos = response.data.contratos;
  userData.faturasAbertas = response.data.faturasAbertas;
  userData.faturasAtrasadas = response.data.faturasAtrasadas;
}

async function verificaNotificacoes(userData: User) {
  const response = await api.get("notificacoes/verificar");
  userData.possuiNotificacao = response.data;
}

export { AuthProvider, useAuth, verificaNotificacoes };
