import { StyleSheet } from "react-native";
import { theme } from "../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tabStyle: {
    width: "auto",
  },
  labelStyle: {
    fontSize: 12,
    fontFamily: theme.fonts.title500,
  },
  style: {
    paddingBottom: 20,
    height: 80,
  },
  notification: {
    position: "absolute",
    top: 0,
    right: -4,
    width: 10,
    height: 10,
    backgroundColor: theme.colors.primary,
    borderRadius: 5,
  },
});
