import Auth from "@aws-amplify/auth";
import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { Button } from "../../../components/Button";
import { Header } from "../../../components/Header";
import { Input } from "../../../components/Input";
import { Label } from "../../../components/Label";
import { ViewLoader } from "../../../components/ViewLoader";
import { AuthError } from "../../../errors/AuthError";
import { theme } from "../../../global/styles/theme";
import { numeroPuro } from "../../../utils/mask";
import {
  yup,
  yupValidate,
  yupValidationCpfCnpj,
} from "../../../utils/yupValidation";
import { styles } from "./styles";

export function ForgotPassword() {
  const [documento, setDocumento] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  const navigation = useNavigation();

  const validationSchema = yup.object().shape({
    documento: yupValidationCpfCnpj,
  });

  async function getConfirmationCode() {
    try {
      setLoading(true);

      await yupValidate(validationSchema, {
        documento,
      });

      await Auth.forgotPassword(numeroPuro(documento))
        .then((response) => {
          console.log(response);
          navigation.navigate("ForgotPasswordConfirmation", {
            documento: numeroPuro(documento),
          });
        })
        .catch((err) => {
          console.log(err);
          throw new AuthError(err.code);
        });
    } catch (err) {
      if (err instanceof Error && err.message) {
        setMessageAlert(err.message);
      } else {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  }

  async function resendSignUp() {
    try {
      setLoading(true);

      await yupValidate(validationSchema, {
        documento,
      });

      await Auth.resendSignUp(numeroPuro(documento)).then(() => {
        navigation.navigate("ForgotPasswordSignUpConfirmation", {
          documento: numeroPuro(documento),
        });
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Header title="" />
      <ViewLoader loading={loading}>
        <Label fieldLabel="Esqueceu sua senha? :(" />
        <Label fieldLabel="Vamos refefini-la." />
        <Label fieldLabel="Informe o seu CPF/CNPJ:" />
        <Input
          value={documento}
          placeholder="CPF/CNPJ"
          mask="cpfCnpj"
          inputChange={(text: any) => setDocumento(text)}
          maxLength={18}
          autoCapitalize="none"
          keyboardType="numeric"
        />

        <View style={styles.viewResendConfirmationCode}>
          <TouchableOpacity onPress={resendSignUp}>
            <Text style={styles.textResendConfirmationCode}>
              Este é meu primeiro acesso.
            </Text>
          </TouchableOpacity>
        </View>

        <Button fieldLabel="Redefinir" onPress={getConfirmationCode} />
      </ViewLoader>

      {messageAlert != "" && (
        <AwesomeAlert
          show={messageAlert != ""}
          message={messageAlert}
          showConfirmButton={true}
          confirmButtonColor={theme.colors.primary}
          confirmText="OK"
          onConfirmPressed={() => {
            setMessageAlert("");
          }}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
        />
      )}
    </>
  );
}
