import { StyleSheet } from "react-native";
import { theme } from "../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    fontFamily: theme.fonts.text400,
    marginTop: 16,
    height: 50,
    // margin: 10,

    width: "100%",
    borderWidth: 1,
    borderColor: "#a09b9b",
    borderRadius: 3,
    backgroundColor: "#fff",
    maxWidth: 500,
    flexDirection: "row",
    alignContent: "center",
    // alignItems: "center",
  },
  input: {
    width: "100%",
    padding: 10,
    flex: 1,
    paddingRight: 10,
    alignContent: "center",
    alignItems: "center",
  },
  icon: {
    alignSelf: "center",
    paddingRight: 10,
    paddingLeft: 10,
    color: theme.colors.secondary,
  },
});
