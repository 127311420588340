import { StyleSheet } from "react-native";
import { theme } from "../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    padding: 10,
    backgroundColor: theme.colors.primary,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    height: 50,
    maxWidth: 500,
  },
  text: {
    fontSize: 18,
    color: "white",
    textTransform: "uppercase",
    fontFamily: theme.fonts.title700,
  },
});
