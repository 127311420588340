import React, { ReactNode } from "react";
import {
  KeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
  ScrollView,
} from "react-native";
import { Loader } from "../Loader";
import { styles } from "./styles";

type Props = KeyboardAvoidingViewProps & {
  loading: boolean;
  children: ReactNode;
};

export function ViewLoader({ loading, children, ...rest }: Props) {
  return (
    <>
      <KeyboardAvoidingView
        // ref={viewRef}
        style={{ flex: 1 }}
        behavior={Platform.OS == "ios" ? "padding" : undefined}
        enabled
        {...rest}
      >
        <ScrollView
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={styles.container}
        >
          {children}
        </ScrollView>
      </KeyboardAvoidingView>
      {loading && <Loader loading={loading} />}
    </>
  );
}
