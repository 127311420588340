import { useNavigation } from "@react-navigation/native";
import React from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import { useAuth } from "../../hooks/auth";
import { Card } from "../Card";
import { styles } from "./styles";

export function Dashboard() {
  const { user } = useAuth();
  const navigation = useNavigation();

  function openInvoices() {
    navigation.navigate("Invoices");
  }

  function openContracts() {
    navigation.navigate("Contracts");
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.subContainer}>
        <Card>
          <TouchableOpacity onPress={openInvoices}>
            <Text style={styles.cardTitle}>Faturas abertas</Text>
            <Text style={styles.cardText}>{user.faturasAbertas}</Text>
          </TouchableOpacity>
        </Card>
        <Card>
          <TouchableOpacity onPress={openInvoices}>
            <Text style={styles.cardTitle}>Faturas atrasadas</Text>
            <Text style={styles.cardText}>{user.faturasAtrasadas}</Text>
          </TouchableOpacity>
        </Card>
        <Card>
          <TouchableOpacity onPress={openContracts}>
            <Text style={styles.cardTitle}>Contratos</Text>
            <Text style={styles.cardText}>{user.contratos}</Text>
          </TouchableOpacity>
        </Card>
      </View>
    </ScrollView>
  );
}
