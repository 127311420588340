import { StyleSheet } from "react-native";
import { bool } from "yup";
import { theme } from "../../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  cardIcon: {
    fontSize: 25,
    color: theme.colors.primary,
    position: "absolute",
    marginTop: 35,
  },
  cardView: {
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "row",
    maxWidth: "100%",
  },
  subCardView: {
    flex: 1,
  },
  separation: {
    marginTop: 10,
  },
  cardViewListEmpty: {
    marginTop: 20,
  },
  cardText: {
    textAlign: "center",
    color: theme.colors.primary,
    fontFamily: theme.fonts.title700,
    fontSize: 16,
    textTransform: "uppercase",
  },
  cardTitle: {
    textAlign: "center",
    fontFamily: theme.fonts.title500,
    color: theme.colors.secondary,
    fontSize: 14,
  },
});
