import { FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";
import { View } from "react-native";
import { theme } from "../../global/styles/theme";
import { ButtonIconLink } from "../ButtonIconLink";
import { styles } from "./styles";

type Props = {
  color?: string;
};

export function SocialMediaView({
  color = theme.colors.secondary,
  ...rest
}: Props) {
  return (
    <View style={styles.container}>
      <ButtonIconLink link="http://vivavox.com.br/">
        <MaterialCommunityIcons name="web" size={30} color={color} />
      </ButtonIconLink>
      <ButtonIconLink link="https://api.whatsapp.com/send?phone=553534733100">
        <FontAwesome5 name="whatsapp" size={30} color={color} />
      </ButtonIconLink>
      <ButtonIconLink link="https://www.facebook.com/vivavoxtelecom/">
        <FontAwesome5 name="facebook" size={30} color={color} />
      </ButtonIconLink>
      <ButtonIconLink link="https://www.instagram.com/vivavoxtelecom_oficial/">
        <FontAwesome5 name="instagram" size={30} color={color} />
      </ButtonIconLink>
    </View>
  );
}
