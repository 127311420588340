import React from "react";
import {
  ActivityIndicator,
  Modal,
  ModalProps,
  Platform,
  Text,
  View,
} from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { theme } from "../../global/styles/theme";
import { styles } from "./styles";

type Props = ModalProps & {
  loading: boolean;
};

export function Loader({ loading = false, ...rest }: Props) {
  // if (Platform.OS === "web") {
  return (
    <AwesomeAlert
      show={loading}
      // title={"Vivavox"}
      showProgress={true}
      progressColor={theme.colors.primary}
      progressSize={"large"}
      message={"Por favor aguarde..."}
      // showConfirmButton={true}
      // confirmButtonColor={theme.colors.primary}
      // confirmText="OK"
      // onConfirmPressed={() => {

      // closeOnTouchOutside={true}
      // closeOnHardwareBackPress={false}
    />
  );
  // } else {
  //   return (
  //     <Modal
  //       transparent={true}
  //       animationType={"none"}
  //       visible={loading}
  //       onRequestClose={() => {
  //         console.log("close modal");
  //       }}
  //       {...rest}
  //     >
  //       <View style={styles.modalBackground}>
  //         <View style={styles.activityIndicatorWrapper}>
  //           <ActivityIndicator size="large" color={theme.colors.primary} />
  //           <Text style={styles.text}>Por favor aguarde...</Text>
  //         </View>
  //       </View>
  //     </Modal>
  //   );
  // }
}
