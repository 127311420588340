import { FontAwesome5, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import * as React from "react";
import { View } from "react-native";
import { theme } from "../global/styles/theme";
import { useAuth } from "../hooks/auth";
import { Contracts } from "../screens/app/Contracts";
import { Home } from "../screens/app/Home";
import { Invoices } from "../screens/app/Invoices";
import { Notifications } from "../screens/app/Notifications";
import { styles } from "./app.styles";

const Tab = createBottomTabNavigator();

export function AppRoutes() {
  const { user } = useAuth();
  return (
    <Tab.Navigator
      initialRouteName="Home"
      tabBarOptions={{
        labelStyle: styles.labelStyle,
        tabStyle: styles.tabStyle,
        style: styles.style,
        activeTintColor: theme.colors.primary,
        inactiveTintColor: theme.colors.secondary,
      }}
    >
      <Tab.Screen
        name="Home"
        component={Home}
        options={{
          title: "Início",
          tabBarLabel: "Home",
          tabBarIcon: ({ color }) => (
            <FontAwesome5 name="home" size={30} color={color} />
          ),
        }}
      />
      <Tab.Screen
        name="Invoices"
        component={Invoices}
        options={{
          title: "Faturas",
          tabBarLabel: "Faturas",
          tabBarIcon: ({ color }) => (
            <MaterialIcons name="attach-money" size={40} color={color} />
          ),
        }}
      />
      <Tab.Screen
        name="Contracts"
        component={Contracts}
        options={{
          title: "Contratos",
          tabBarLabel: "Contratos",
          tabBarIcon: ({ color }) => (
            <FontAwesome5 name="file-signature" size={30} color={color} />
          ),
        }}
      />
      <Tab.Screen
        name="Notification"
        component={Notifications}
        options={{
          title: "Notificações",
          tabBarLabel: "Notificações",
          tabBarIcon: ({ color }) => (
            <View>
              {user.possuiNotificacao && <View style={styles.notification} />}
              <Ionicons name="notifications" size={30} color={color} />
            </View>
          ),
        }}
      />
    </Tab.Navigator>
  );
}
