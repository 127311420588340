import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useState } from "react";
import AwesomeAlert from "react-native-awesome-alerts";
import { Button } from "../../../components/Button";
import { Header } from "../../../components/Header";
import { Input } from "../../../components/Input";
import { Label } from "../../../components/Label";
import { ViewLoader } from "../../../components/ViewLoader";
import ApiError from "../../../errors/ApiError";
import { theme } from "../../../global/styles/theme";
import { AuthParamList } from "../../../routes/auth.routes";
import { api } from "../../../services/api";
import {
  yup,
  yupValidate,
  yupValidationConfirmacaoSenha,
  yupValidationSenha,
} from "../../../utils/yupValidation";

type ParamProps = RouteProp<AuthParamList, "SignUpPassword">;

export function SignUpPassword() {
  const navigation = useNavigation();
  const route = useRoute<ParamProps>();

  const { user } = route.params;

  const [senha, setSenha] = useState("");
  const [senhaRepetida, setSenhaRepetida] = useState("");

  const [loading, setLoading] = useState(false);

  const [messageAlert, setMessageAlert] = useState("");

  const signUpValidationSchema = yup.object().shape({
    senha: yupValidationSenha,
    senhaRepetida: yupValidationConfirmacaoSenha,
  });

  async function signUp() {
    try {
      setLoading(true);

      await yupValidate(signUpValidationSchema, {
        senha,
        senhaRepetida,
      });

      const data = {
        documento: user.documento,
        celular: user.celular,
        email: user.email,
        senha,
      };

      await api
        .post("cadastros/cadastrar", data)
        .then(() => {
          navigation.navigate("SignUpConfirmation", {
            documento: user.documento,
          });
        })
        .catch((err) => {
          console.log(err);
          throw new ApiError(err);
        });
    } catch (err) {
      if (err instanceof Error && err.message) {
        setMessageAlert(err.message);
      } else {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Header title="" />
      <ViewLoader loading={loading}>
        <Label fieldLabel="Informe sua senha:" />

        <Input
          value={senha}
          placeholder="SENHA"
          inputChange={(text: any) => setSenha(text)}
          secureTextEntry
          autoCompleteType="password"
        />
        <Input
          value={senhaRepetida}
          placeholder="REPITA A SENHA"
          inputChange={(text: any) => setSenhaRepetida(text)}
          secureTextEntry
          autoCompleteType="password"
          submitFunction={signUp}
        />
        <Button fieldLabel="Continuar" onPress={signUp} />
      </ViewLoader>

      {messageAlert != "" && (
        <AwesomeAlert
          show={messageAlert != ""}
          message={messageAlert}
          showConfirmButton={true}
          confirmButtonColor={theme.colors.primary}
          confirmText="OK"
          onConfirmPressed={() => {
            setMessageAlert("");
          }}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
        />
      )}
    </>
  );
}
