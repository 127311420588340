import { StyleSheet } from "react-native";
import { theme } from "../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    top: 20,
    marginLeft: 10,
    marginRight: 10,
  },
});
