/* eslint-disable no-console */
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useState } from "react";
import AwesomeAlert from "react-native-awesome-alerts";
import { Button } from "../../../components/Button";
import { Header } from "../../../components/Header";
import { Input } from "../../../components/Input";
import { Label } from "../../../components/Label";
import { ViewLoader } from "../../../components/ViewLoader";
import ApiError from "../../../errors/ApiError";
import { theme } from "../../../global/styles/theme";
import { AuthParamList } from "../../../routes/auth.routes";
import { api } from "../../../services/api";
import { numeroPuro } from "../../../utils/mask";
import {
  yup,
  yupValidate,
  yupValidationCell,
  yupValidationCpfCnpj,
  yupValidationEmail,
} from "../../../utils/yupValidation";

type ParamProps = RouteProp<AuthParamList, "SignUpValidation">;

export function SignUpValidation() {
  const navigation = useNavigation();
  const route = useRoute<ParamProps>();

  const { user } = route.params;

  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");

  const [loading, setLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  const signUpValidationSchema = yup.object().shape({
    documento: yupValidationCpfCnpj,
    celular: yupValidationCell,
    email: yupValidationEmail,
  });

  async function signUp() {
    try {
      setLoading(true);

      let numeroCelular = numeroPuro(celular);
      if (numeroCelular && numeroCelular.length == 11) {
        numeroCelular = "55" + numeroCelular;
      }

      const data = {
        documento: user.documento,
        celular: numeroCelular,
        email,
      };

      await yupValidate(signUpValidationSchema, data);

      await api
        .post("cadastros/validar", data)
        .then((response) => {
          navigation.navigate("SignUpPassword", {
            user: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
          throw new ApiError(err);
        });
    } catch (err) {
      if (err instanceof Error && err.message) {
        setMessageAlert(err.message);
      } else {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Header title="" />
      <ViewLoader loading={loading}>
        <Label fieldLabel="Preencha os campos abaixo com os seus dados de cadastro:" />
        <Input
          value={celular}
          mask="celular"
          placeholder={user.celular ? user.celular : "CELULAR"}
          inputChange={(text: any) => setCelular(text)}
          maxLength={19}
          autoCapitalize="none"
          keyboardType="numeric"
        />
        <Input
          value={email}
          placeholder={user.email ? user.email : "E-MAIL"}
          inputChange={(text: any) => setEmail(text)}
          autoCapitalize="none"
          submitFunction={signUp}
        />

        <Button fieldLabel="Continuar" onPress={signUp} />
      </ViewLoader>

      {messageAlert != "" && (
        <AwesomeAlert
          show={messageAlert != ""}
          message={messageAlert}
          showConfirmButton={true}
          confirmButtonColor={theme.colors.primary}
          confirmText="OK"
          onConfirmPressed={() => {
            setMessageAlert("");
          }}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
        />
      )}
    </>
  );
}
