function numeroPuro(value: string) {
  if (value) value = value.replace(/\D/g, "");
  return value;
}

function maskCpfCnpj(value: string) {
  if (value) {
    value = numeroPuro(value);
    if (value.length <= 11) {
      value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, "$1.$2.$3-$4");
    } else if (value.length <= 14) {
      value = value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d)/,
        "$1.$2.$3/$4-$5"
      );
    }
  }
  return value;
}

function maskCelular(value: string, full: boolean) {
  if (value) {
    value = numeroPuro(value);
    if (value.length == 13) {
      if (full)
        value = value.replace(/^(\d{2})(\d{2})(\d{5})(\d)/, "+$1 ($2) $3-$4");
      else value = value.replace(/^(\d{2})(\d{2})(\d{5})(\d)/, "($2) $3-$4");
    } else if (value.length == 11) {
      value = value.replace(/^(\d{2})(\d{5})(\d)/, "($1) $2-$3");
    }
  }
  return value;
}

interface IMask {
  mask?: "cpfCnpj" | "celular" | "celular11" | undefined;
}

interface IApplyMask extends IMask {
  value: string;
}

function applyMask(
  value: string | undefined,
  mask: "cpfCnpj" | "celular" | "celular11" | undefined
) {
  if (!value) return value;
  if (!mask) return value;
  if (mask === "cpfCnpj") return maskCpfCnpj(value);
  if (mask === "celular") return maskCelular(value, true);
  if (mask === "celular11") return maskCelular(value, false);
  return value;
}

export { numeroPuro, applyMask, IMask };
