import { StyleSheet } from "react-native";
import { theme } from "../../../global/styles/theme";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  cardViewPaid: {
    opacity: 0.5,
  },
  cardView: {
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "row",
    maxWidth: "100%",
  },
  cardViewListEmpty: {
    marginTop: 20,
  },
  subCardView: {
    flex: 1,
  },
  separation: {
    marginTop: 10,
  },
  cardBadgeUnpaid: {
    left: 1,
    top: 1,
    position: "absolute",
    marginTop: -22,
    marginLeft: -22,
    marginBottom: 5,
    width: 100,
    backgroundColor: theme.colors.yellow,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    fontSize: 14,
  },
  cardBadgeExpired: {
    left: 1,
    top: 1,
    position: "absolute",
    marginTop: -22,
    marginLeft: -22,
    marginBottom: 5,
    width: 100,
    backgroundColor: theme.colors.red,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    fontSize: 14,
  },
  cardBadgePaid: {
    left: 1,
    top: 1,
    position: "absolute",
    marginTop: -22,
    marginLeft: -22,
    marginBottom: 5,
    width: 100,
    backgroundColor: theme.colors.primary,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    fontSize: 14,
  },
  cardBadgeText: {
    fontFamily: theme.fonts.title500,
    color: theme.colors.secondary,
    textAlign: "center",
  },
  cardBadgeTextExpired: {
    fontFamily: theme.fonts.title500,
    color: theme.colors.heading,
    textAlign: "center",
  },
  cardTitle: {
    textAlign: "center",
    fontFamily: theme.fonts.title500,
    color: theme.colors.secondary,
    fontSize: 14,
  },
  cardText: {
    textAlign: "center",
    color: theme.colors.primary,
    fontFamily: theme.fonts.title700,
    fontSize: 16,
    textTransform: "uppercase",
  },
  textDecorationLine: {
    textDecorationLine: "line-through",
  },
  textDecorationLineNone: {
    textDecorationLine: "none",
  },

  containerFilter: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },

  buttonAction: {
    justifyContent: "center",
    borderWidth: 1,
    borderColor: theme.colors.primary,
    backgroundColor: theme.colors.primary,
    borderRadius: 5,
    alignItems: "center",
    marginHorizontal: 5,
    paddingHorizontal: 10,
    flexDirection: "row",
  },

  iconButtonAction: {
    color: theme.colors.secondary,
  },

  viewDownload: {
    flexDirection: "row",
    marginVertical: 10,
    alignItems: "center",
  },

  textButtonAction: {
    fontFamily: theme.fonts.title700,
    color: theme.colors.secondary,
    justifyContent: "center",
    marginLeft: 10,
  },

  buttonFilter: {
    width: 110,
    height: 80,
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: theme.colors.secondary,
    alignItems: "center",
    marginHorizontal: 5,
    borderRadius: 10,
    marginTop: 10,
  },

  textFilter: {
    fontFamily: theme.fonts.title700,
    color: theme.colors.heading,
    fontSize: 15,
  },
});
