import React, { ReactNode } from "react";
import {
  Linking,
  Platform,
  TouchableOpacity,
  TouchableOpacityProps,
} from "react-native";
import { styles } from "./styles";

type Props = TouchableOpacityProps & {
  children: ReactNode;
  link: string;
};

function openLink(link: string) {
  if (Platform.OS == "web") {
    window.open(link, "_blank");
  } else {
    Linking.openURL(link);
  }
}

export function ButtonIconLink({ children, link, ...rest }: Props) {
  return (
    <TouchableOpacity
      style={styles.container}
      {...rest}
      activeOpacity={0.8}
      onPress={() => {
        openLink(link);
      }}
    >
      {children}
    </TouchableOpacity>
  );
}
