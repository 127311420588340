import _ from "lodash";

const ApiErrors = {
  "000": "Ops, algo deu errado. Tente novamente mais tarde.",
  "001.001":
    "Ops, você não tem permissão para acessar este conteúdo. Autentique-se novamente.",
  "002.001":
    "Que pena, não encontramos seus dados em nossa base. Por favor, entre em contato com nosso setor comercial.",
  "002.002":
    "Ops, tem alguma coisa errada... Conferimos seus dados e aparentemente estão divergentes. Por favor, entre em contato com nosso setor comercial.",
  "002.003":
    "Ops, parece que você já tem uma conta. Caso não lembre sua senha, clique em 'Esqueci minha senha' na tela inicial.",
  "002.004":
    "Que pena, não encontramos uma forma de contatar você para confirmar sua identidade. Por favor, entre em contato com nosso setor comercial.",
  "002.005":
    "Este código de verificação está expirado. Por favor, solicite um novo.",
  "003.001":
    "Não conseguimos encontrar seus dados. Tente novamente mais tarde.",
  "004.001":
    "Não conseguimos encontrar seus dados. Tente novamente mais tarde.",
  TIMED_OUT:
    "Ixe. A comunicação com o servidor está um pouco lenta. Por favor, tente novamente mais tarde.",
};

class ApiError extends Error {
  constructor(error: any) {
    super();
    this.name = "Vivavox";
    this.message = _.get(ApiErrors, error.response.data.code);
    if (!this.message) {
      this.message = _.get(ApiErrors, "000");
    }
    this.stack = new Error().stack;
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export default ApiError;
