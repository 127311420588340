import React from "react";

import { Text } from "react-native";
import { RectButton, RectButtonProps } from "react-native-gesture-handler";
import { styles } from "./styles";

type Props = RectButtonProps & {
  fieldLabel: string;
};

export function Button({ fieldLabel, ...rest }: Props) {
  return (
    <RectButton style={styles.container} {...rest}>
      <Text style={styles.text}>{fieldLabel}</Text>
    </RectButton>
  );
}
