import { useNavigation } from "@react-navigation/native";
import { AxiosRequestConfig } from "axios";
import React, { useState } from "react";
import AwesomeAlert from "react-native-awesome-alerts";
import myAlert from "../../../components/Alert";
import { Button } from "../../../components/Button";
import { Header } from "../../../components/Header";
import { Input } from "../../../components/Input";
import { Label } from "../../../components/Label";
import { ViewLoader } from "../../../components/ViewLoader";
import ApiError from "../../../errors/ApiError";
import { theme } from "../../../global/styles/theme";
import { api } from "../../../services/api";
import { numeroPuro } from "../../../utils/mask";
import {
  yup,
  yupValidate,
  yupValidationCpfCnpj,
} from "../../../utils/yupValidation";

export function SignUp() {
  const navigation = useNavigation();

  const [documento, setDocumento] = useState("");

  const [loading, setLoading] = useState(false);

  const [messageAlert, setMessageAlert] = useState("");

  const signUpValidationSchema = yup.object().shape({
    documento: yupValidationCpfCnpj,
  });

  async function signUp() {
    try {
      setLoading(true);

      await yupValidate(signUpValidationSchema, {
        documento,
      });

      const axiosConfig: AxiosRequestConfig = {
        params: {
          documento: numeroPuro(documento),
        },
      };

      await api
        .get("cadastros/procurar", axiosConfig)
        .then((response) => {
          const user = response.data;
          navigation.navigate("SignUpValidation", {
            user: user,
          });
        })
        .catch((err) => {
          throw new ApiError(err);
        });
    } catch (err) {
      if (err instanceof Error && err.message) {
        setMessageAlert(err.message);
      }
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Header title="" />
      <ViewLoader loading={loading}>
        <Label fieldLabel="Que bom te ver por aqui! :)" />
        <Label fieldLabel="Para acessar o aplicativo é necessário cria uma conta." />
        <Label fieldLabel="Vamos criar a sua?" />
        <Label fieldLabel="Informe o seu CPF/CNPJ:" />
        <Input
          value={documento}
          mask="cpfCnpj"
          placeholder="CPF/CNPJ"
          inputChange={(text: any) => setDocumento(text)}
          maxLength={18}
          autoCapitalize="none"
          keyboardType="numeric"
          submitFunction={signUp}
        />
        <Button fieldLabel="Continuar" onPress={signUp} />
      </ViewLoader>
      {messageAlert != "" && (
        <AwesomeAlert
          show={messageAlert != ""}
          message={messageAlert}
          showConfirmButton={true}
          confirmButtonColor={theme.colors.primary}
          confirmText="OK"
          onConfirmPressed={() => {
            setMessageAlert("");
          }}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
        />
      )}
    </>
  );
}
