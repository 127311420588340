import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";
import myAlert from "../components/Alert";
import ValidationError from "../errors/ValidationError";
import { api } from "./api";

async function createExpoPushToken(): Promise<string> {
  const expoPushToken = await registerForPushNotificationsAsync();
  if (expoPushToken) {
    await saveExpoPushToken(expoPushToken);
  }
  return expoPushToken;
}

async function registerForPushNotificationsAsync(): Promise<string> {
  try {
    if (!Constants.isDevice || Platform.OS === "web") {
      return "";
    }

    let token = "";
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      throw new ValidationError(
        "Falha ao obter token push para notificação push!"
      );
    }

    token = (await Notifications.getExpoPushTokenAsync()).data;

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    return token;
  } catch (err) {
    myAlert(err.name, err.message);
    return "";
  }
}

async function saveExpoPushToken(expoPushToken: string) {
  var data = {
    chave: expoPushToken,
  };
  await api
    .post("dispositivos", data)
    .then(() => {
      AsyncStorage.setItem("expoPushToken", expoPushToken);
    })
    .catch((err) => {
      console.log(err);
    });
}

async function deleteExpoPushToken() {
  const expoPushToken = await AsyncStorage.getItem("expoPushToken");
  if (expoPushToken != null) {
    var chave = encodeURIComponent(expoPushToken);
    await api.delete(`dispositivos/${chave}`).catch((err) => {
      console.log(err);
    });
  }
}

export { createExpoPushToken, deleteExpoPushToken };
